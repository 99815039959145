import type { PresetChipTypes } from '@dx-ui/osc-chip';
import { Chip, presetFilter } from '@dx-ui/osc-chip';
import { useTranslation } from 'react-i18next';
import { useIsClient } from 'usehooks-ts';

export type HotelBadgeTypes = Extract<PresetChipTypes, 'new' | 'sale'> | 'reno';

export const HotelBadges = ({
  badges,
  renoHotelLabel,
}: {
  badges: HotelBadgeTypes[];
  renoHotelLabel?: string;
}) => {
  const { t } = useTranslation('hotel-badges');
  const isClient = useIsClient();
  return badges.length > 0 && isClient ? (
    <div className="my-1 flex gap-1">
      {badges
        .filter((badge) => badge === 'reno' || presetFilter(badge))
        .map((badge) =>
          badge === 'reno' ? (
            <Chip
              key={badge}
              data-testid="HotelBadge"
              size="sm"
              label={badge === 'reno' ? renoHotelLabel : t(badge)}
            />
          ) : (
            <Chip key={badge} data-testid="HotelBadge" preset={badge} size="sm" />
          )
        )}
    </div>
  ) : null;
};
