import { useRef, useEffect, useMemo } from 'react';
import { generateMPAQueryArray } from './utils/generate-mpa-query-array';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useSmbToggle, useWrappedRouter } from '../../hooks';
import type { ShopMultiPropAvailConstraints } from './utils';
import {
  arrayChunk,
  generateShopMultiPropAvailInputVariables,
  getHashedMPADataFromCache,
  getConstraintsFromCachedData,
} from './utils';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import generateMayBeAvailableHashData from './utils/generate-may-be-available-hash-data';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useLocation } from '@dx-ui/framework-location-provider';
import type { ShopMultiPropAvailPointsQuery } from '../../gql/operations';
import { useAppState } from '../../providers/app-provider';
import { getPageType } from '../../utils';

/**
 *
 * @param {Number} [chunkSize=20] - chunk size for parallel queries
 * @param {Number} [maxSize=150] - max number of ctyhocns to retrieve pricing for
 * @param {ShopMultiPropAvailQueryVariables} variables query variables for shopMultiPropAvail
 */
const useShopMultiPropAvail = ({
  maxSize = 150,
  ctyhocns,
  enabled = true,
  mpaConstraints = false,
}: {
  maxSize?: number;
  ctyhocns?: string[];
  enabled?: boolean;
  mpaConstraints?: boolean;
}) => {
  const { router, safeQueryParams: queryParameters } = useWrappedRouter();
  const { guestInfo, isLoading: isAuthLoading } = useAuth();
  const { country: guestLocationCountry, isLoading: isGuestLocationLoading } = useLocation();
  const queryClient = useQueryClient();
  const { shouldUsePoints, hotelsInBounds } = useAppState();
  const ctyhocnsToFetchPrice = ctyhocns ? ctyhocns : Object.keys(hotelsInBounds);
  const constraintsFromCache = useRef<ShopMultiPropAvailConstraints>({
    isPastDate: false,
    invalidOfferId: false,
    hasPointsRateChange: false,
    hasRateChange: false,
    isOfferNotAvailable: false,
  });
  const { programAccountId } = useSmbToggle();

  //When we route again (query params change) need to reset constraints and whether we have fetched for bounds
  useEffect(() => {
    constraintsFromCache.current = {
      isPastDate: false,
      invalidOfferId: false,
      hasPointsRateChange: false,
      hasRateChange: false,
      isOfferNotAvailable: false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParameters)]);

  const pageType = getPageType(router.asPath, queryParameters);
  const enableQuery =
    isBrowser &&
    !isAuthLoading &&
    !isGuestLocationLoading &&
    enabled &&
    router.isReady &&
    pageType.isSearch &&
    !pageType.isPointsExplorer &&
    !queryParameters?.datesFlex;

  const CHUNK_SIZE = 20;

  //format input graph object
  const input = generateShopMultiPropAvailInputVariables({
    guestInfo,
    queryParameters,
    shouldUsePoints,
    guestLocationCountry,
    smbProgramAccountId: programAccountId,
  });

  //get hash table data from mpa cache
  const initialHashedShopMultiPropAvailDataFromCache = getHashedMPADataFromCache(
    queryClient,
    input,
    shouldUsePoints
  );
  const initialHashedKeys = Object.keys(initialHashedShopMultiPropAvailDataFromCache);
  //only allow up to the max of max size ctyhocns to be fetched that had not already been fetched before
  const ctyhocnToFetchPricingFor =
    ctyhocnsToFetchPrice
      ?.reduce((ctyhocnsToFetch, ctyhocn) => {
        if (!initialHashedKeys.includes(ctyhocn)) ctyhocnsToFetch.push(ctyhocn);
        return ctyhocnsToFetch;
      }, [] as string[])
      .slice(0, maxSize) || [];

  // split ctyhocn array of size maxSize into chunks of size chunkSize
  const chunkedMPA = arrayChunk(ctyhocnToFetchPricingFor, CHUNK_SIZE);

  //create an array of query objects. Each object is identical except for contents of ctyhocn array
  const queryArr = generateMPAQueryArray({
    chunkedMPA,
    enabled: enableQuery,
    input,
    language: 'en',
  });

  //parallel fetch MPA chunks
  const results = useQueries({ queries: queryArr });

  const {
    hashedData: shopMultiPropAvailCacheDataAfterQuery,
    isError,
    isLoading,
    hasMPACallResolved,
    constraints,
  } = useMemo(() => {
    //if this is dateFlex or flexibleDates scenario we dont use MPA data
    if (queryParameters?.datesFlex) {
      return {
        hashedData: {} as Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>,
        isLoading: false,
        isError: false,
        hasMPACallResolved: false,
        constraints: constraintsFromCache.current,
      };
    }
    //HACK: Paul. This kind of is questionable. We should find a better way to determine error state for a ctyhocn. Generates mayBeAvailable data for ctyhocns that fail fetch
    const mayBeAvailableHashData = generateMayBeAvailableHashData(results, chunkedMPA);
    const hashedMPADataFromCache = {
      ...getHashedMPADataFromCache(queryClient, input, shouldUsePoints),
    };

    //hash all existing shopAvail entries in cache
    const hashedData = { ...hashedMPADataFromCache, ...mayBeAvailableHashData };

    const isLoading = results.some((result) => result.isFetching);
    //either assign new constraints once loading is finished or use previous constraints object while loading
    constraintsFromCache.current =
      !isLoading && isBrowser && mpaConstraints
        ? getConstraintsFromCachedData(hashedData)
        : constraintsFromCache.current;

    return {
      hashedData,
      isError:
        results.some((result) => result.isError) || Object.keys(mayBeAvailableHashData).length > 0,
      isLoading,
      hasMPACallResolved: results.some((result) => result.isSuccess),
      constraints: constraintsFromCache.current,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUsePoints, queryParameters?.datesFlex, results]);

  // consolidate all query results into a single hash table. Data on its own is relatively useless due to O(N) lookup
  return {
    hashedData: shopMultiPropAvailCacheDataAfterQuery,
    isLoading,
    isError,
    hasMPACallResolved,
    constraints,
  };
};

export default useShopMultiPropAvail;
