import type { QueryParameters } from '../../../constants';
import { AMEX_EXCEPTION_CTYHOCN, publicRuntimeConfig } from '../../../constants';
import type { AvailStatus, PageType } from '../../../utils';
import { generateCTAFunnelUrl } from '../../../utils';
import type { MapLanguage } from '@dx-ui/framework-uri-builder';
import type { ConversionCurrencyOptions } from '@dx-ui/osc-currency-converter';
import { formatAndConvertPrice } from '@dx-ui/osc-currency-converter';
import {
  getAvailabilityText,
  getPreSellPreOpenInfo,
  isLocalCurrencySameAsHotelCurrency,
} from './hotel-info-utils';
import type { RateInfoMessage, RateMessage } from '../../rate-name-cta/rate-name-cta';
import type { HotelType } from '../../../providers/app-provider/app-provider.types';
import type { TFunction } from 'i18next';
import type { Hotel } from '../../../gql/types';
import type { HotelInfoAttributes } from './hotel-info-utils';

type HotelSummaryData = Pick<
  HotelType,
  | 'amenityIds'
  | 'brandCode'
  | 'contactInfo'
  | 'ctyhocn'
  | 'disclaimers'
  | 'display'
  | 'distance'
  | 'distanceFmt'
  | 'facilityOverview'
  | 'leadRate'
  | 'localization'
  | 'name'
  | 'tripAdvisorLocationSummary'
>;

export type HotelCardData =
  | (Pick<
      Hotel,
      | 'amenities'
      | 'brandCode'
      | 'contactInfo'
      | 'ctyhocn'
      | 'disclaimers'
      | 'display'
      | 'distance'
      | 'distanceFmt'
      | 'facilityOverview'
      | 'leadRate'
      | 'localization'
      | 'name'
      | 'tripAdvisorLocationSummary'
    > &
      HotelSummaryData)
  | null;

export function getHotelLeadRateInfo({
  hotelData,
  locale,
  ctyhocn,
  usePoints,
  selectedCurrency,
  hasConnectingRooms,
  currenciesError,
  currenciesMap,
  pageType,
  t,
  queryParameters,
}: {
  hotelData: HotelCardData; //HotelData | HotelType | null | undefined;
  locale: string;
  ctyhocn: string | undefined;
  usePoints: boolean;
  selectedCurrency: string | null;
  hasConnectingRooms: boolean;
  currenciesError: Error;
  currenciesMap: Map<string, ConversionCurrencyOptions>;
  pageType: PageType;
  t: TFunction<['hotel-card', 'rate-name-cta', 'locations-page']>;
  queryParameters: QueryParameters | undefined;
}): HotelInfoAttributes {
  const availStatus = (
    hotelData?.display?.open === false
      ? 'NOT_OPEN'
      : hotelData?.display?.open && !hotelData?.leadRate?.lowest
      ? 'NOT_AVAILABLE'
      : 'AVAILABLE'
  ) as AvailStatus;

  const { isResPreSell, preSellDateFmt, isNoResPreOpen, isPreOpen, preOpenDateFmt } =
    getPreSellPreOpenInfo(
      locale,
      availStatus === 'NOT_OPEN',
      hotelData?.display?.openDate,
      hotelData?.display?.resEnabledDate,
      hotelData?.display?.resEnabled
    );

  const href = isNoResPreOpen
    ? undefined
    : generateCTAFunnelUrl({
        availStatus,
        host: publicRuntimeConfig.HONORS_HOST,
        ctyhocn: ctyhocn as string,
        shouldUsePoints: usePoints,
        currencyCode: selectedCurrency as string,
        preOpenDate: (isResPreSell
          ? hotelData?.display?.resEnabledDate
          : isPreOpen
          ? hotelData?.display?.openDate
          : '') as string,
        hasConnectingRooms,
        isPointsExplorer: false,
        isDreams: true,
        lang: locale as MapLanguage,
        queryParameters,
      });

  // for dream pages prices are fetched server side before currency query is fired. so if currency query isnt complete show initial rates (local) first
  const convertedPrice = isLocalCurrencySameAsHotelCurrency(
    selectedCurrency,
    hotelData?.localization?.currencyCode || ''
  )
    ? hotelData?.leadRate?.lowest?.rateAmountFmt
    : formatAndConvertPrice({
        basePrice: !hotelData?.leadRate?.lowest?.rateAmount
          ? undefined
          : hotelData?.leadRate?.lowest?.rateAmount,
        currencyQueryError: !!currenciesError,
        fromCurrency: currenciesMap?.get('USD'),
        language: locale,
        toCurrency: currenciesMap?.get(selectedCurrency as string),
      }) || hotelData?.leadRate?.lowest?.rateAmountFmt;
  const points = hotelData?.leadRate?.hhonors?.lead?.dailyRmPointsRateNumFmt;

  const disclaimerMessages: RateInfoMessage[] = [];

  let amexDisclaimer = '';
  if (pageType.isResorts) {
    const amex =
      (hotelData?.disclaimers?.length &&
        hotelData?.disclaimers.find((disclaimer) => disclaimer.type === 'amexUnsupported')) ||
      undefined;
    amexDisclaimer = ctyhocn === AMEX_EXCEPTION_CTYHOCN ? t('amexDisclaimer') : amex?.desc ?? '';
  }
  if (amexDisclaimer)
    disclaimerMessages.push({
      heading: {
        text: amexDisclaimer,
      },
      type: 'amexDisclaimer',
    });
  disclaimerMessages.push({
    heading: {
      text: usePoints
        ? t('locations-page:pointsLegalDisclaimer')
        : t('locations-page:legalDisclaimer'),
    },
  });

  //if we use usePoints but points rate is not defined fallback to currency rate
  const rate = usePoints ? points || convertedPrice : convertedPrice;
  const ratePlanName = usePoints
    ? hotelData?.leadRate?.hhonors?.lead?.ratePlan?.ratePlanName ??
      hotelData?.leadRate?.lowest?.ratePlan?.ratePlanName
    : hotelData?.leadRate?.lowest?.ratePlan?.ratePlanName;
  const ratePlanDesc = usePoints
    ? hotelData?.leadRate?.hhonors?.lead?.ratePlan?.ratePlanDesc ??
      hotelData?.leadRate?.lowest?.ratePlan?.ratePlanDesc
    : hotelData?.leadRate?.lowest?.ratePlan?.ratePlanDesc;
  const buttonLabel = isNoResPreOpen ? undefined : t('selectDates');

  const { comingSoonAndSoldOutCaption, comingSoonAndSoldOutMessage } = getAvailabilityText({
    t,
    isPreSell: isResPreSell,
    isPreOpen,
    isSoldOut: availStatus === 'NOT_AVAILABLE',
    openDate: isResPreSell ? preSellDateFmt : isPreOpen ? preOpenDateFmt : undefined,
    preOpenMsg: hotelData?.display?.preOpenMsg,
  });
  const showDisplayFeeTransparencySubMsg =
    !hotelData?.leadRate?.lowest?.cmaTotalPriceIndicator &&
    !usePoints &&
    Boolean(hotelData?.leadRate?.lowest?.feeTransparencyIndicator);

  let isNewHotel = false;

  if (hotelData?.amenities)
    isNewHotel = !!hotelData?.amenities?.find((amenity) => amenity.id === 'newHotel');
  else if (hotelData?.amenityIds)
    isNewHotel = !!hotelData?.amenityIds?.find((amenity) => amenity === 'newHotel');

  const tripAdvisorLocationSummary = hotelData?.tripAdvisorLocationSummary || null;

  const messages: RateInfoMessage[] = [];

  const hotelName = hotelData?.name || '';

  if (comingSoonAndSoldOutMessage) {
    messages.push(
      {
        isCaption: true,
        heading: { text: comingSoonAndSoldOutCaption || '' },
      },
      { heading: { text: comingSoonAndSoldOutMessage || '' } }
    );
  } else if (!!usePoints && !!points) {
    messages.push(
      {
        isCaption: true,
        heading: {
          text: points || '',
          className: 'text-xl font-headline',
        },
        prefix: { text: t('priceFrom') },
        suffix: {
          text: t('pointsPerNight'),
          infoIconOrPopup: {
            align: 'right',
            infoText: ratePlanDesc || '',
            id: `lead-rate-info-${ctyhocn}`,
          },
        },
        type: 'UsePoints',
      },
      {
        heading: { text: ratePlanName || '' },
      }
    );
  } else if ((!!rate && !!ratePlanDesc) || (usePoints && !points && !!rate && !!ratePlanDesc)) {
    const displayFeeTransparencySubMsg = showDisplayFeeTransparencySubMsg
      ? { heading: { text: t('rate-name-cta:includesFees') } }
      : undefined;
    messages.push(
      {
        isCaption: true,
        heading: {
          text: convertedPrice || '',
          infoIconOrPopup: {
            align: 'right',
            infoText: ratePlanDesc || '',
            id: `lead-rate-info-${ctyhocn}`,
          },
        },
        prefix: { text: t('priceFrom') },
      },
      displayFeeTransparencySubMsg as RateInfoMessage,
      {
        heading: { text: ratePlanName || '' },
      }
    );
  }

  const ctaLabel = buttonLabel
    ? ({
        text: buttonLabel,
        screenReaderText: t('rate-name-cta:opensInNewTab'),
        type: usePoints && !!points ? 'UsePoints' : undefined,
      } as RateMessage)
    : undefined;

  //NHCSEARCH-5249 MVT Compare Properties
  let customParams = {};
  if (availStatus === 'AVAILABLE') customParams = { showCompare: true };
  return {
    disclaimerMessages,
    isNewHotel,
    ctaLabel,
    ctaHref: href,
    customParams,
    hotelName,
    messages,
    tripAdvisorLocationSummary,
  };
}
